@import './../App.scss';

.switch {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .bouton {
        // border: 1px solid blue;
        width: 55px;
        height: 28px;
        border-radius: 13px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        transition: background-color 250ms 15ms ease-in-out;

        &.true{
            background-color: $vert;
        }
        &.false{
            background-color: $grisLight;
        }

        .curseur {
            // border: 1px solid orange;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            box-sizing: border-box;
            position: absolute;
            top: 3px;
            left: 3px;
            transition: transform 220ms cubic-bezier(.5,.87,.05,1);
            background-color: $blanc_casse;
        }
    }

    .label{
        margin-left: 10px;
        font-size: 0.75em;
        position: relative;
        bottom: 2px;
    }
}