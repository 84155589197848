@import './App.scss';

nav {
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    position: relative;

    .souligne{
        background-color: $vert;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: 2px;
        transition: transform 300ms 35ms cubic-bezier(.6,.73,.07,.99);

        &.left{
            transform: translateX(0);
        }
        &.right{
            transform: translateX(100%);
        }
    }

    div {
        width: 50%;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;

        a {
            // border: 1px solid orange;
            display: block;
            text-align: center;
            text-decoration: none;
            color: $gris;
            font-family: "interR";
        }
    }
}