@import './../../App.scss';

.mon-profil {
    margin-top: 30px;
    color: $gris;
    position: relative;

    h1 {
        // border: 1px solid red;
        font-weight: normal;
        font-family: 'interL';
        font-size: 1.7em;
        margin: 0;
        margin-bottom: 10px;

        span {
            font-size: 0.6em;
            margin: 0;
            margin-left: 2px;
            display: block;
            margin-top: 5px;

            a {
                text-decoration: none;
                color: $vert;
                font-family: 'interM';
            }
        }
    }

    h2 {
        margin: 0;
        // border: 1px solid blue;
        font-weight: normal;
        font-size: 1em;
        font-family: 'interL';
    }

    header {

        margin-bottom: 25px;

        .changeMdp {
            // border: 1px solid red;
            font-size: 0.8em;
            background-color: $vert;
            margin-top: 20px;
            width: 180px;
            color: $blanc_casse;
            padding: 10px;
            border-radius: 6px;
            text-align: center;
            font-family: "interL";
            cursor: pointer;
        }
    }

    .votre-agence,
    .vos-donnees,
    .vos-parametres,
    .vos-lecons,
    .vos-examens {
        margin-bottom: 80px;
        // border: 1px solid red;
        text-align: center;
        // border: 1px solid blue;
        text-align: left;

        h1 {
            font-size: 1.5em;
            // margin: auto;
            padding: 0;
            padding-bottom: 10px;
            display: block;
            border-bottom: 2px solid $vert;
        }

        .listeLessons,
        .listeExamens {
            font-size: 0.87em;

            ul {
                // border: 1px solid red;
                margin: 0;
                margin-left: 15px;
                padding: 0;

                li {
                    margin: 0;
                    margin-bottom: 20px;
                    padding: 0;
                    font-size: 1.2em;
                    
                    span{
                        font-size: 0.8em;
                        font-style: italic;
                        color: $gris;
                    }
                    
                    &.past {
                        color: $grisLight;

                        strong {
                            background-color: transparent;
                            font-size: 1em;
                        }
                    }

                    &.futur {
                        font-size: 1.3em;

                        strong {
                            background-color: $vert;
                            color: $blanc_casse;
                            font-weight: normal;
                            font-size: 0.95em;
                            padding: 2px;
                        }
                    }
                }
            }

            &>div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    margin-bottom: 10px;

                    .absent {
                        color: $blanc_casse;
                        background-color: $saumon;
                        font-family: "interR";
                        padding: 2px;
                        font-weight: normal;
                    }

                    strong {
                        background-color: $vert;
                        color: $blanc_casse;
                        width: 35px;
                        text-align: center;
                        font-weight: normal;
                        display: inline-block;
                        font-size: 0.9em;
                        padding: 2px;
                    }

                    div {
                        font-family: "interM";
                        display: block;
                        margin-top: 5px;
                        opacity: 0.8;
                    }

                    button {
                        display: inline-block;
                        width: 50px;
                        height: 25px;
                        padding: 2px !important;
                    }
                }
            }

            .past,
            .absent {

                strong {
                    background-color: $grisLight;
                    font-weight: normal;
                    font-size: 0.9em;
                    padding: 2px;
                }
            }

            .absent {
                strong {
                    background-color: $saumon;
                }
            }
        }

        article {
            text-align: center;
            margin-top: 25px;
            font-size: 1.2em;

            p {
                margin: 5px;
            }

            .tel {
                font-size: 0.9em;
                margin-top: 10px;
            }

            .email {
                font-size: 0.7em;
            }

            .infosSupp {
                font-size: 0.8em;
                font-family: "interL";
                margin-top: 5px;

                p {
                    margin-bottom: 10px;
                    // text-align: left;

                    strong {
                        background-color: $vert;
                        color: $blanc_casse;
                        font-weight: normal;
                        padding: 2px;
                        display: inline-block;
                        margin-top: 2px;
                    }
                }
            }
        }
    }

    .windowChangeMdp {
        border: 1px solid $grisXLight;
        position: absolute;
        top: 50px;
        padding: 25px 10px 25px 10px;
        width: 100%;
        height: 250px;
        background-color: white;
        transition: opacity 500ms ease-in-out, transform 10ms ease-in-out;
        box-shadow: 0px 8px 20px 10px rgba($color: $gris, $alpha: 0.25);
        border-radius: 6px;

        section {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            input {
                display: block;
                height: 50px;
                width: 85%;
                box-sizing: border-box;
                padding-left: 10px;
                transition: opacity 200ms ease-in-out;
                border: 1px solid $grisLight;
                border-radius: 6px;
                font-family: "interM";
                color: $gris;

                &.submit {
                    background-color: $vert;
                    color: $blanc_casse;
                    border-color: $vert;
                }
            }

            div {
                width: 85%;
                margin-bottom: 5px;

                p {
                    font-family: 'interR';
                    margin: 0;
                    font-size: 0.8em;
                    color: $grisLight;
                    transition: color 200ms 12ms ease-in-out;

                    &.check {
                        color: $vert;
                    }

                    span {
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        background-color: $vert;
                        display: inline-block;
                        margin-right: 5px;
                        transition: opacity 150ms ease-in-out;
                    }
                }
            }
        }
    }
}