@import './../App.scss';

.flottant {
    font-family: "interL";
    position: fixed;
    width: 88%;
    box-sizing: border-box;
    left: 5%;
    top: 200px;
    background-color: $blanc_casse;
    box-shadow: 0px 8px 20px 10px rgba($color: $gris, $alpha: 0.25);
    border: 1px solid $grisXLight;
    color: $gris;
    border-radius: 12px;
    z-index: 100;
    padding: 10px;
    transition: opacity 250ms ease-in;

    .cross {
        position: absolute;
        top: -20px;
        right: -20px;
        background-color: $grisLight;
        color: white;
        width: 34px;
        height: 34px;
        border-radius: 18px;
        cursor: pointer;

        img {
            width: 60%;
            position: absolute;
            top: 20%;
            left: 20%;
            transform: rotate(45deg);
        }
    }

    .label {
        border-bottom: 1px solid $vert;
        padding-bottom: 5px;
        font-size: 1.1em;
        font-family: "interR";
        color: $gris;
        margin: auto;
        display: inline-block;

        &.saumon {
            border-bottom: 1px solid $saumon;
        }
    }

    .date {
        font-family: 'interM';
        font-size: 1.3em;
        // border: 1px solid blue;
        text-align: center;
    }

    .buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 15px;
        margin-bottom: 15px;

        button {
            display: block;
        }
    }
}