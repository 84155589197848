@import './../App.scss';

.connexion {
    // border: 1px solid blue;

    section {
        width: 320px;
        margin: auto;
        margin-top: 100px;
        box-shadow: 0px 8px 10px 0px rgba($color: $gris, $alpha: 0.25);
        padding: 15px;
        border-radius: 12px;
        text-align: center;

        p {
            // border: 1px solid red;
            width: 85%;
            margin: auto;
            color: $gris;
            margin-bottom: 10px;
        }

        .bad,
        .new {
            width: 85%;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 5px;
            border-radius: 6px;
            padding: 10px;
            box-sizing: border-box;
            color: $saumon;
            font-family: "interL";
            font-size: 0.8em;
            background-color: rgba($color: $saumon, $alpha: 0.25);
            border: 1px solid $saumon;

            em {
                display: inline-block;
                margin-top: 10px;
            }
        }

        .switch {
            width: 85%;
            margin: auto;
            display: flex;
            align-items: center;
            .label {
                color: $gris;
            }
        }

        .new {
            color: $vert;
            border-color: $vert;
            background-color: rgba($color: $vert, $alpha: 0.25);
        }

        img {
            width: 125px;
            margin-top: 15px;
        }

        h2 {
            // border: 1px solid red;
            font-weight: normal;
            color: $gris;
            text-align: center;
            margin: 0;
            font-size: 1.3em;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        input,
        button {
            display: block;
            height: 50px;
            margin: auto;
            margin-bottom: 15px;
            width: 85%;
            box-sizing: border-box;
            padding-left: 10px;
            transition: opacity 200ms ease-in-out;
            border: 1px solid $grisLight;
            border-radius: 6px;
            font-family: "interM";
            color: $gris;
        }

        button {
            cursor: pointer;
            color: white;
            border: none;
            font-family: "interR";
            font-size: 0.75em;
            transition: opacity 250ms ease;
        }

        .forgotPassword {
            width: 85%;
            margin: auto;
            text-align: left;
            color: $bleu !important;
            display: block;
            font-size: 0.8em;
            text-decoration: none;
            font-style: italic;
            margin-top: 8px;
            margin-bottom: 15px;
        }
    }
}