@import './../../App.scss';

.page_calendrier {

    .credit {
        text-align: center;
        margin-top: 25px;
        font-size: 1.1em;
        color: $gris;
        font-family: "interL";

        strong {
            display: block;
            color: $vert;
            font-weight: normal;
            font-size: 2.7em;
            margin-top: 5px;
            font-family: "interB";

            span{
                font-size: 0.5em;
                font-family: "interM";
                // color: $gris;
            }
        }

        p{
            // border: 1px solid blue;
            margin: 0;
            margin-top: 5px;
            font-size: 0.9em;
            em{
                background-color: $vert;
                color: $blanc_casse;
                font-style: normal;
                padding: 2px;
                font-size: 1em;
                font-family: "interM";
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        h2 {
            font-weight: normal;
            color: $gris;
        }

        .arrow {
            width: 30px;
            padding: 0;
            cursor: pointer;

            &.p {
                transform: rotate(90deg);
            }

            &.n {
                transform: rotate(-90deg);
            }

            img {
                margin: 0;
            }

        }
    }

    .lignes {

        // border: 1px solid blue;

        .colonnes {
            display: flex;
            justify-content: space-between;
            // border: 1px solid orange;

            .colonne {
                margin-top: 15px;
                width: 30%;

                header {

                    color: $grisClair;

                    h2,
                    h3 {
                        font-weight: normal;
                        margin: 0;
                        text-align: center;
                        font-family: "interR";
                    }

                    h2 {
                        font-size: 1.3em;
                    }

                    h3{
                        font-family: "interL";
                        font-size: 0.75em;
                    }
                }

                .separation {
                    width: 25%;
                    height: 3px;
                    margin: auto;
                    margin-bottom: 25px;
                    background-color: $grisLight;
                }

                section {

                    &>div {
                        article {

                            // border: 1px solid red;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 10px;
                            margin: 12px 0 12px 0;
                            border-radius: 6px;
                            font-size: 0.9em;
                            border: 1px solid $vert;
                            font-family: "interR";

                            &.dispo {
                                background-color: $blanc_casse;
                                cursor: pointer;
                                color: $vert;
                            }

                            &.reserve {
                                cursor: pointer;
                                background-color: $vert;
                                color: $blanc_casse
                            }

                            &.indispo {
                                border: none;
                                color: $grisLight;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

        }
    }

    .non-dispo{
        // border: 1px solid red;
        margin-top: 15px;
        min-height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gris;
        font-size: 1.25em;
    }
}